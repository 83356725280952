<template>
  <div class="row mt-3" aria-label="Explanation">
    <div class="col-sm-12 col-md-auto mb-1 mb-md-0">
      <button :disabled="fetchingExplanation || !graphsAllReadyToExplain" type="button" :class="{ 'explanation-loading': fetchingExplanation }" class="btn btn-primary interaction-button" @click="fetchExplanation">{{explainButtonTitle}}</button>
    </div>
    <!-- Add a bit of margin for separation on smaller screens and keep buttons aligned -->
    <div class="col-sm-12 col-md-auto">
      <button type="button" class="btn btn-secondary interaction-button" @click="openModal">📝 Edit Prompt</button>
    </div>
    <div class="container mt-2" v-if="explanationText">
      <div class="row">
        <div class="col">
          <div class="border p-3" style="max-height: 300px; overflow-y: auto;">
            <h6>Explanation</h6>
            <p class="preserve-whitespace" aria-label="Explanation Text">{{ explanationText }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" ref="explanationPromptModal" tabindex="-1" aria-labelledby="explanationPromptModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="explanationPromptModalLabel">Edit Explanation Prompt</h5>
        </div>
        <div class="modal-body">
          <textarea v-model="explanationPrompt" rows="10" class="form-control" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="updatePrompt">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Modal } from 'bootstrap/dist/js/bootstrap';
import 'bootstrap/scss/bootstrap.scss';

export default {
  name: 'ExplanationPanel',
  data() {
    return {
      explanationText: null,
      fetchingExplanation: false,
      explanationPrompt: this.defaultExplanationPrompt,
    };
  },
  computed: {
    explainButtonTitle() {
      if (this.fetchingExplanation) {
        return '🔄 Fetching Explanation, may take a while...';
      }
      return '✨Explain';
    },
    graphsAllReadyToExplain() {
      if (!this.graphsToExplain?.length) {
        return false;
      }
      return this.graphsToExplain.every((graph) => graph.graphToExplain);
    },
  },
  props: {
    graphsToExplain: {
      type: Array,
      default: () => [],
      required: true,
    },
    defaultExplanationPrompt: {
      type: String,
      default: "Can you explain what this graph means? Assume your audience has a technical college degree, but is not a computer scientist. Don't say that I gave you the image, just explain it as if it was your own. Be confident in your explanation, and don't use wishy-washy terms like 'appears' or 'perhaps'. Please give details about the data in the graph, and point out any interesting trends. Try to give a good final analysis given the trends in the data, speaking to the features and their influence on the rankings.",
    },
  },
  methods: {
    async svgStringToBase64Png(svgString, width = 800, height = 600) {
      return new Promise((resolve, reject) => {
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(svgBlob);
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          const base64Png = canvas.toDataURL('image/png');
          URL.revokeObjectURL(url);
          resolve(base64Png);
        };
        img.onerror = () => {
          const errorMessage = 'Had trouble transforming SVGs to PNGs. Please wait for the SVGs to all load and try again.';
          console.error(errorMessage);
          URL.revokeObjectURL(url);
          reject(new Error(errorMessage));
        };
        img.src = url;
      });
    },
    async fetchExplanation() {
      this.fetchingExplanation = true;
      this.explanationText = null;

      try {
        const graphsToExplainWithBase64Url = await Promise.all(
          this.graphsToExplain.map(async (graph) => {
            const base64Url = await this.svgStringToBase64Png(graph.graphToExplain);
            const { typeOfGraphToExplain, ...grapWithoutSVG } = graph;
            return { ...grapWithoutSVG, base64Url, typeOfGraphToExplain };
          }),
        );

        const explanationBody = {
          prompt: this.explanationPrompt,
          graphsToExplain: graphsToExplainWithBase64Url,
        };
        const explanationResponse = await fetch('/api/explanation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(explanationBody),
        });
        if (explanationResponse.status !== 200) {
          throw new Error(
            `👺 ${explanationResponse.status} ${explanationResponse.statusText}`,
          );
        }
        const explanationJson = await explanationResponse.json();
        this.explanationText = explanationJson.explanation;
      } catch (error) {
        const explanationError = `👺 Error fetching explanation: ${error}`;
        console.error(explanationError);
        this.explanationText = explanationError;
        return explanationError;
      } finally {
        this.fetchingExplanation = false;
      }
      return this.explanationText;
    },
    updatePrompt() {
      this.closeModal();
    },
    openModal() {
      const modal = new Modal(this.$refs.explanationPromptModal);
      modal.show();
    },
    closeModal() {
      const modal = Modal.getInstance(this.$refs.explanationPromptModal);
      if (modal) {
        modal.hide();
      }
    },
  },
};
</script>

<style scoped>
.interaction-button {
  width: auto; /* Only take up as much width as needed */
  white-space: nowrap; /* Prevent the text from wrapping */
}

@keyframes throbbing {
  0% {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.3;
  }
}

.explanation-loading {
  animation: throbbing 2s ease-in-out infinite;
}

.preserve-whitespace {
  white-space: pre-wrap;
}
</style>
