<template>
  <div class="container-fluid">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'tree' }"
          id="pills-tree-tab"
          data-toggle="pill"
          href="#pills-tree"
          role="tab"
          aria-controls="pills-tree"
          aria-selected="true"
          @click="activeTab = 'tree'"
        >Decision Tree</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'forest' }"
          id="pills-forest-tab"
          data-toggle="pill"
          href="#pills-forest"
          role="tab"
          aria-controls="pills-forest"
          aria-selected="true"
          @click="activeTab = 'forest'"
        >Decision Forest</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'surrogate' }"
          id="pills-surrogate-tab"
          data-toggle="pill"
          href="#pills-surrogate"
          role="tab"
          aria-controls="pills-surrogate"
          aria-selected="false"
          @click="activeTab = 'surrogate'"
        >Surrogate Tree</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'compare' }"
          id="pills-compare-tab"
          data-toggle="pill"
          href="#pills-compare"
          role="tab"
          aria-controls="pills-compare"
          aria-selected="false"
          @click="activeTab = 'compare'"
        >Compare</a>
      </li>
    </ul>

    <div class="tab-content border" style="border-width: 1px; border-color: #dee2e6;">
      <div
        v-if="activeTab === 'tree'"
        class="tab-pane fade show active"
        id="pills-show"
        role="tabpanel"
        aria-labelledby="pills-show-tab"
      >
        <ShowDecisionTree />
      </div>
      <div
        v-if="activeTab === 'forest'"
        class="tab-pane fade show active"
        id="pills-forest"
        role="tabpanel"
        aria-labelledby="pills-forest-tab"
      >
        <ShowDecisionForest />
      </div>
      <div
        v-else-if="activeTab === 'compare'"
        class="tab-pane fade show active"
        id="pills-compare"
        role="tabpanel"
        aria-labelledby="pills-compare-tab"
      >
        <CompareFeatureImportance />
      </div>
      <div
        v-else-if="activeTab === 'surrogate'"
        class="tab-pane fade show active"
        id="pills-compare"
        role="tabpanel"
        aria-labelledby="pills-surrogate-tab"
      >
        <SurrogateModel />
      </div>
    </div>
  </div>
</template>

<style scoped>
.nav-pills .nav-link {
  border-radius: 0.25rem;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.tab-content {
  background: #f8f9fa;
  padding: 15px;
  border: 1px solid rgba(0,0,0,.125);
  border-top: none;
  border-radius: 0.25rem;
  min-height: 500px;
}
</style>

<script>
import ShowDecisionTree from './ShowDecisionTree.vue';
import ShowDecisionForest from './ShowDecisionForest.vue';
import CompareFeatureImportance from './CompareFeatureImportance.vue';
import SurrogateModel from './SurrogateModel.vue';

export default {
  components: {
    ShowDecisionTree,
    ShowDecisionForest,
    CompareFeatureImportance,
    SurrogateModel,
  },
  data() {
    return {
      activeTab: 'forest',
    };
  },
};
</script>
