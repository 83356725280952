<template>
  <div>
    <div class="row">
      <div class="form-group">
        <label for="inputDataSelector" class="form-label smaller-text">Input Data:</label>
        <select class="form-select form-select-sm" id="inputDataSelector" v-model="inputDataSelected">
          <option
            v-for="inputData in inputDataOptions"
            :key="inputData.filename"
            :value="inputData.filename">
            {{ inputData.filename }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <label for="featureSelector" class="form-label smaller-text">Features:</label>
        <div id="featureSelector">
          <div v-for="feature in availableFeatures" :key="feature" class="form-check">
            <button
              type="button"
              class="btn btn-sm fixed-width-button smaller-text"
              :class="getButtonClass(specificFeaturesSelected[feature])"
              @click="cycleFeatureState(feature)">
              {{ feature }}: {{ specificFeaturesSelected[feature] || 'ignore' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchInputDataOptions,
} from './lib/utils';

export default {
  name: 'FeatureAndInputSelector',
  props: {
    multiSelectTargets: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['controlsUpdated'],
  data() {
    return {
      inputDataSelected: null,
      inputDataOptions: [],
      specificFeaturesSelected: {},
    };
  },
  computed: {
    selectedInputMetaData() {
      // eslint-disable-next-line vue/max-len
      return this.inputDataOptions?.find((inputData) => inputData.filename === this.inputDataSelected)?.metadata;
    },
    availableFeatures() {
      if (this.selectedInputMetaData?.type) {
        return Object.keys(this.selectedInputMetaData?.type);
      }
      return [];
    },
  },
  watch: {
    inputDataSelected() {
      this.selectDefaultFeatures();
    },
    specificFeaturesSelected: {
      handler() {
        this.$emit('controlsUpdated', this.$data);
      },
      deep: true,
    },
  },
  async mounted() {
    this.inputDataOptions = await fetchInputDataOptions();
    this.inputDataSelected = this.inputDataOptions?.[0]?.filename;
  },
  methods: {
    featureCannotBeUsedAsTarget(feature) {
      return (this.selectedInputMetaData.type[feature] === 'continuous'
          || (this.selectedInputMetaData.type[feature] === 'categorical'
          && this.selectedInputMetaData.cardinality[feature] > 10));
    },
    cycleFeatureState(feature) {
      const currentState = this.specificFeaturesSelected[feature];
      let newState;
      if (currentState === 'target') {
        newState = 'ignore';
      } else if (currentState === 'feature') {
        // If the feature in the metadata has continuous, or categorical with > 10 features,
        // then we can't use it as a feature, so switch it to "ignore"
        if (this.featureCannotBeUsedAsTarget(feature)) {
          newState = 'ignore';
        } else {
          newState = 'target';
        }
      } else {
        newState = 'feature';
      }
      if (!this.multiSelectTargets && newState === 'target') {
        Object.keys(this.specificFeaturesSelected).forEach((key) => {
          if (this.specificFeaturesSelected[key] === 'target') {
            this.specificFeaturesSelected[key] = 'ignore';
          }
        });
      }

      this.specificFeaturesSelected[feature] = newState;
      this.specificFeaturesSelected = { ...this.specificFeaturesSelected };
    },
    getButtonClass(state) {
      switch (state) {
        case 'target':
          return 'btn-success';
        case 'feature':
          return 'btn-warning';
        case 'ignore':
        default:
          return 'btn-secondary';
      }
    },
    selectDefaultFeatures() {
      this.specificFeaturesSelected = {};
      this.availableFeatures.forEach((feature) => {
        this.specificFeaturesSelected[feature] = 'ignore';
      });
      if (this.availableFeatures.length > 1) {
        // working backwards, find the first feature that can be used as a target
        let targetHasBeenSet = false;
        for (let i = this.availableFeatures.length - 1; i >= 0; i -= 1) {
          const feature = this.availableFeatures[i];
          if (!targetHasBeenSet && !this.featureCannotBeUsedAsTarget(feature)) {
            this.specificFeaturesSelected[feature] = 'target';
            targetHasBeenSet = true;
          } else {
            this.specificFeaturesSelected[feature] = 'feature';
          }
        }
      }
      this.specificFeaturesSelected = { ...this.specificFeaturesSelected };
    },
  },
};

</script>

<style scoped>
.smaller-text {
  font-size: 0.7rem;
}

.fixed-width-button {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
