<template>
  <div class="container-responsive">
    <div class="row">
      <div class="col-md-2">
        <h6 class="row">XGBoost Controls</h6>
        <decision-forest-controls
          @controlsUpdated="updateForestModel" />
        <div v-if="forestStats">
          <h6 class="row mt-4">Decision Forest Statistics</h6>
          <div class="smaller-text">
            <p class="row">Test Accuracy: {{ (forestStats.test_accuracy * 100).toFixed(1) }}%</p>
            <p class="row">Training Accuracy: {{ (forestStats.training_accuracy * 100).toFixed(1) }}%</p>
            <p class="row">Total Trees Used: {{ forestStats.total_num_trees }}</p>
          </div>
        </div>
        <PredictionPanel
          :features="featuresSelected"
          :metaData="metaDataSelected"
          @clearedPrediction="clearForestPrediction"
          @predictionToBeGenerated="generatePredictionForForest" />
        <div class="row" v-if="forestPrediction">
          <h6 class="row">Prediction</h6>
          <div class="smallest-text">
            <p class="smaller-text row" v-for="predictionResult in forestPrediction" :key="predictionResult.target">
              {{ predictionResult.target }}: {{ (predictionResult.probability * 100).toFixed(1) }}%
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div ref="cytoContainer">
          <div ref="cyto" style="border: solid; border-color: lightgrey; height:100%" />
        </div>
      </div>
      <div class="col-md-2">
        <h6 class="row">Surrogate Tree Controls </h6>
        <decision-tree-controls
          :hide-inputs="true"
          @controlsUpdated="updateTreeModel" />
        <div v-if="treeStats" class="row">
          <h6 class="row mt-4">Decision Tree Statistics</h6>
          <div class="smaller-text">
            <p class="row">Test Accuracy: {{ (treeStats.test_accuracy * 100).toFixed(1) }}%</p>
            <p class="row">Training Accuracy: {{ (treeStats.training_accuracy * 100).toFixed(1) }}%</p>
          </div>
        </div>
        <PredictionPanel
          :features="featuresSelected"
          :metaData="metaDataSelected"
          @clearedPrediction="clearPredictionPath"
          @predictionToBeGenerated="generatePredictionPath" />
        <div class="row">
          <div class="form-group">
            <label for="importanceSelector" class="row form-label">Importance Graph:</label>
            <select
              class="row form-select form-select-sm"
              id="importanceSelector"
              v-model="importanceSelected"
              @change="updateImportanceSVG">
              <option value="shapley_summary">Shapley Summary</option>
              <option value="shapley_beeswarm">Shapley Beeswarm</option>
              <option value="shapley_scatter">Shapley Scatter</option>
              <option value="gini">Gini</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div
            v-if="featureImportanceSVG"
            :class="importanceSelected === 'gini' ? 'long-svg-container' : 'svg-container'"
            class="summaryGraphSVG row"
            @click="openSVG(featureImportanceSVG)"
            @keypress="openSVG(featureImportanceSVG)">
            <div v-html="featureImportanceSVG" class="row mt-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap/scss/bootstrap.scss';
import 'tippy.js/dist/tippy.css';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import DecisionTreeControls from './DecisionTreeControls.vue';
import DecisionForestControls from './DecisionForestControls.vue';
import PredictionPanel from './PredictionPanel.vue';
import { openSVGInNewTab, getTreeFeatureImportanceGraph, generateForestPrediction } from './lib/utils';

import {
  initializeCyto, addToolTips, showPredictedPath,
} from './lib/cytoscapeManager';

export default {
  components: {
    DecisionTreeControls,
    DecisionForestControls,
    PredictionPanel,
  },
  data() {
    return {
      cyto: null,
      treeConfiguration: null,
      forestConfiguration: null,
      importanceSelected: 'shapley_summary',
      targetMapping: [],
      treeStats: null,
      forestStats: null,
      featureImportanceSVG: null,
      featureValues: {},
      selectedFeatureValues: {},
      waterfallGraphSVG: null,
      forceGraphSVG: null,
      cytoscapeInitialized: false,
      forestPrediction: null,
    };
  },
  mounted() {
    this.resizeCyto();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeCyto);
  },
  computed: {
    randomStateComputed() {
      return this.forestConfiguration.randomStateSelected
        ? this.forestConfiguration.randomStateSelected : null;
    },
    featuresSelected() {
      if (!this.forestConfiguration?.specificFeaturesSelected) return [];
      const featuresInUse = Object.entries(this.forestConfiguration.specificFeaturesSelected)
        .filter(([, value]) => value === 'feature')
        .map(([key]) => key);
      return featuresInUse;
    },
    metaDataSelected() {
      // eslint-disable-next-line vue/max-len
      return this.forestConfiguration?.inputDataOptions?.find((inputDataOption) => inputDataOption.filename === this.forestConfiguration.inputDataSelected)?.metadata;
    },
    targetSelected() {
      if (!this.forestConfiguration?.specificFeaturesSelected) return [];
      const targetsInUse = Object.entries(this.forestConfiguration.specificFeaturesSelected)
        .filter(([, value]) => value === 'target')
        .map(([key]) => key);
      // should only be one target
      if (targetsInUse.length === 1) return targetsInUse[0];
      throw new Error('There should only be one target selected');
    },
  },
  methods: {
    openSVG(featureImportanceSVG) {
      openSVGInNewTab(featureImportanceSVG);
    },
    resizeCyto() {
      const newHeight = `${window.innerHeight - 100}px`;
      this.$refs.cyto.style['min-height'] = newHeight;
      this.$refs.cyto.style.height = newHeight;
      if (this.cyto) {
        this.cyto.resize();
        this.cyto.fit();
        this.runCytoLayout();
      }
    },
    openPredictionPathModal() {
      this.$refs.predictionPathModal.modalInstance = new Modal(this.$refs.predictionPathModal);
      this.$refs.predictionPathModal.modalInstance.show();
    },
    clearPredictionPath() {
      // Resetting all elements to their default style
      this.cyto.batch(() => {
        this.cyto.elements().style('background-color', 'blue');
      });
    },
    clearForestPrediction() {
      this.forestPrediction = null;
    },
    async generatePredictionForForest(selectedFeatureValues) {
      this.forestPrediction = await generateForestPrediction({
        selectedFeatureValues,
        forestConfiguration: this.forestConfiguration,
        metaDataSelected: this.metaDataSelected,
        targetSelected: this.targetSelected,
        importanceSelected: this.importanceSelected,
      });
    },
    async generatePredictionPath(selectedFeatureValues) {
      showPredictedPath(selectedFeatureValues, this.metaDataSelected, this.cyto);

      const optionsForFeatureImportanceGraph = {
        ...this.treeConfiguration,
        inputDataSelected: this.forestConfiguration.inputDataSelected,
        specificFeaturesSelected: this.forestConfiguration.specificFeaturesSelected,
        importanceSelected: this.importanceSelected,
        featureValues: selectedFeatureValues,
      };

      this.forceGraphSVG = await getTreeFeatureImportanceGraph({
        ...optionsForFeatureImportanceGraph,
        importanceSelected: 'shapley_force',
      });

      this.waterfallGraphSVG = await getTreeFeatureImportanceGraph({
        ...optionsForFeatureImportanceGraph,
        importanceSelected: 'shapley_waterfall',
      });
    },
    runCytoLayout() {
      this.cyto.layout({
        name: this.treeConfiguration.selectedLayout, animate: true, fit: true, animationDuration: 300, animationEasing: 'ease-in',
      }).run();
    },
    async updateImportanceSVG() {
      this.featureImportanceSVG = await getTreeFeatureImportanceGraph({
        ...this.treeConfiguration,
        inputDataSelected: this.forestConfiguration.inputDataSelected,
        specificFeaturesSelected: this.forestConfiguration.specificFeaturesSelected,
        importanceSelected: this.importanceSelected,
      });
    },
    updateForestModel(incomingForestConfiguration) {
      this.forestConfiguration = incomingForestConfiguration;
      this.updateSurrogateModel();
    },
    updateTreeModel(incomingTreeConfiguration) {
      this.treeConfiguration = incomingTreeConfiguration;
      this.updateSurrogateModel();
    },
    async updateSurrogateModel() {
      if (!this.treeConfiguration || !this.forestConfiguration) return;

      // eslint-disable-next-line vue/max-len
      if (!this.cytoscapeInitialized) this.cyto = initializeCyto(this.$refs.cyto, this.treeConfiguration.selectedLayout, this.resizeCyto);
      this.cyto.elements().remove();
      try {
        const url = `/api/surrogate/${this.forestConfiguration.inputDataSelected}/?export_type=JSON`;
        const surrogateBody = {
          inputForest: this.forestConfiguration,
          outputTree: this.treeConfiguration,
        };
        const surrogateGraphResponse = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(surrogateBody),
        });
        if (surrogateGraphResponse.status !== 200) throw new Error(`👺 ${surrogateGraphResponse.status} ${surrogateGraphResponse.statusText}`);
        const surrogateTreeGraph = await surrogateGraphResponse.json();
        console.debug('🌳 Surrogate tree graph: ', surrogateTreeGraph);
        this.cyto.add(surrogateTreeGraph.elements);
        this.treeStats = surrogateTreeGraph.tree_stats;
        this.forestStats = surrogateTreeGraph.forest_stats;
        this.featureValues = surrogateTreeGraph.ordinal_mapping;
        this.targetMapping = surrogateTreeGraph.target_mapping;
        addToolTips(this.cyto.elements());
        this.runCytoLayout();
        this.updateImportanceSVG();
      } catch (error) {
        console.error(`👺 Error rendering surrogate tree: ${error}`);
      }
    },
  },
};
</script>

<style scoped>
    .smaller-text {
        font-size: 0.7rem;
    }
</style>
