<template>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="smaller-text" for="forestDepth">Tree Depth:</label>
        <input
          type="number"
          class="form-control"
          id="forestDepth"
          v-model="maxTreeDepth"
          min="1"
          step="1"
          @change="emitUpdate">
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label class="smaller-text" for="randomState">Random State:</label>
        <input
          type="number"
          step="1"
          class="form-control"
          id="randomState"
          v-model="randomStateSelected"
          placeholder="None"
          @change="emitUpdate">
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="form-group">
      <label for="testTrainSplit" class="form-label smaller-text">Test/Train split:</label>
      <input
        type="number"
        class="form-control form-control-sm"
        id="testTrainSplit"
        min="0"
        max="0.99"
        step="0.1"
        v-model="testTrainSplit"
        @change="emitUpdate" />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="smaller-text" for="learningRate">Learning Rate:</label>
        <input
          type="number"
          class="form-control"
          id="learningRate"
          min="0"
          max="0.99"
          step="0.1"
          v-model="learningRate"
          @change="emitUpdate">
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label class="smaller-text" for="boostingRounds">Boosting #:</label>
        <input
          type="number"
          class="form-control"
          id="learninboostingRoundsgRate"
          step="1"
          v-model="boostingRounds"
          @change="emitUpdate">
      </div>
    </div>
  </div>
  <div v-if="!hideInputs" class="row">
    <FeatureAndInputSelector
      :multiSelectTargets="false"
      @controlsUpdated="featuresAndInputUpdated"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import FeatureAndInputSelector from './FeatureAndInputSelector.vue';

export default {
  name: 'DecisionForestControls',
  emits: ['controlsUpdated'],
  components: {
    FeatureAndInputSelector,
  },
  data() {
    return {
      maxTreeDepth: 3,
      learningRate: 1.0,
      objectiveSelected: 'multi:softprob',
      randomStateSelected: 0,
      boostingRounds: 100,
      testTrainSplit: 0.2,
      inputDataSelected: null,
      inputDataOptions: [],
      specificFeaturesSelected: {},
    };
  },
  props: {
    hideInputs: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.hideInputs) {
      this.emitUpdate();
    }
  },
  methods: {
    emitUpdate: debounce(function emitUpdate() {
      this.$emit('controlsUpdated', this.$data);
    }, 750),
    featuresAndInputUpdated(newFeaturesData) {
      this.specificFeaturesSelected = newFeaturesData.specificFeaturesSelected;
      this.inputDataSelected = newFeaturesData.inputDataSelected;
      this.inputDataOptions = newFeaturesData.inputDataOptions;
      this.emitUpdate();
    },
  },
};
</script>

<style scoped>
    .smaller-text {
        font-size: 0.7rem;
    }
</style>
