import cytoscape from 'cytoscape';
import popper from 'cytoscape-popper';
import dagre from 'cytoscape-dagre';
import spread from 'cytoscape-spread';
import euler from 'cytoscape-euler';
import cise from 'cytoscape-cise';
import fcose from 'cytoscape-fcose';
import cola from 'cytoscape-cola';
import avsdf from 'cytoscape-avsdf';
import elk from 'cytoscape-elk';
import tippy, { followCursor } from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export function initializeCyto(container, layoutConfig, resizeCyto) {
  if (typeof cytoscape('core', 'popper') === 'undefined') {
    cytoscape.use(popper);
    cytoscape.use(elk);
    cytoscape.use(avsdf);
    cytoscape.use(cola);
    cytoscape.use(fcose);
    cytoscape.use(cise);
    cytoscape.use(euler);
    cytoscape.use(spread);
    cytoscape.use(dagre);
  }
  const cy = cytoscape({
    container,
    style: [
      {
        selector: 'node',
        style: {
          'background-color': 'blue',
          color: 'white',
          'text-wrap': 'wrap',
          label: 'data(feature_label)',
          width: 'label',
          height: 'label',
          padding: 10,
          shape: 'roundrectangle',
          'text-valign': 'center',
          'text-halign': 'center',
        },
      },
      {
        selector: 'edge',
        style: {
          width: 3,
          'line-color': '#ccc',
          'target-arrow-color': '#ccc',
          label: 'data(label)',
          'target-arrow-shape': 'triangle',
          'curve-style': 'bezier',
        },
      },
    ],
    layout: layoutConfig,
  });
  window.addEventListener('resize', resizeCyto.bind(null, cy));
  return cy;
}

function getToolTipContent(elementData) {
  if (elementData.source && elementData.target) {
    // we're an edge
    return elementData.label;
  }
  // we're a node, so return contents in html
  let content = `
          <strong>Label:</strong> ${elementData.raw_label}<br>
          <strong>Impurity:</strong> ${elementData.impurity.toFixed(2)}<br>
          <strong>Samples:</strong> ${elementData.samples}<br>
      `;
  if (elementData.feature_importance_score) {
    content = `
          ${content}
          <strong>Feature Importance Rank:</strong> ${elementData.feature_importance_rank}<br>
          <strong>Feature Importance Score:</strong> ${elementData.feature_importance_score.toFixed(2)}<br>
        `;
  }
  if (elementData.threshold) {
    content = `
          <strong>Test:</strong> ${elementData.threshold_human}<br>
          ${content}
          <strong>Threshold:</strong> <= ${elementData.threshold}<br>
          <strong>Most Likely Classes:</strong> ${elementData.most_likely_classes}<br>
        `;
    if (elementData.possible_values) {
      let possibleValuesLabel;
      if (elementData.possible_values.length > 10) {
        // truncate the array
        possibleValuesLabel = `${elementData.possible_values.slice(0, 10).join(', ')}...`;
      }
      else {
        possibleValuesLabel = `${elementData.possible_values.join(', ')}`;
      }
      content = `${content}<br>
      <strong>Possible Values:</strong> ${possibleValuesLabel}`;
    }
  }
  return content;
}

function createTippy(element) {
  const elementData = element.data();
  const content = getToolTipContent(elementData);
  const ref = element.popperRef();
  // eslint-disable-next-line no-param-reassign
  element.tippy = tippy(document.createElement('div'), {
    getReferenceClientRect: ref.getBoundingClientRect,
    followCursor: true,
    allowHTML: true,
    trigger: 'manual',
    content,
    plugins: [followCursor],
  });
}

export function addToolTips(elements) {
  elements.forEach((element) => {
    createTippy(element);
  });

  elements.unbind('mouseover');
  elements.bind('mouseover', (event) => {
    event.target.tippy.show();
  });

  elements.unbind('mouseout');
  elements.bind('mouseout', (event) => event.target.tippy.hide());
}

export function showPredictedPath(selectedFeatureValues, metaDataSelected, cyto) {
  const predictionPath = [];
  // given the selected feature values, get the prediction path by walking the tree
  const walkTree = (node) => {
    const featureName = node.data('original_label');
    predictionPath.push(node);
    // If the node is a leaf, return the most likely class
    if (!node.data('threshold')) {
      return;
    }

    const threshold = node.data('threshold');

    // Get the value for the current feature from the selectedFeatureValues
    const selectedValue = selectedFeatureValues[featureName];
    const type = metaDataSelected.type[featureName];
    let finalValue = selectedValue;
    if (type === 'ordinal') finalValue = metaDataSelected.ordinal_mapping[featureName].indexOf(selectedValue);

    // Determine the next node based on the current node's threshold
    let nextNode = null;
    if (finalValue <= threshold) {
      const trueEdge = node.connectedEdges()[0];
      predictionPath.push(trueEdge);
      // eslint-disable-next-line prefer-destructuring
      nextNode = trueEdge.connectedNodes()[1];
    } else {
      const falseEdge = node.connectedEdges()[1];
      predictionPath.push(falseEdge);
      // eslint-disable-next-line prefer-destructuring
      nextNode = falseEdge.connectedNodes()[1];
    }

    // Traverse to the next node recursively
    walkTree(nextNode);
  };

  // Start walking the tree from the root node
  const rootNode = cyto.nodes('[!source]')[0];
  walkTree(rootNode);

  // Reset all elements styling to default
  cyto.elements().style('background-color', 'blue');
  // Add a new class for the nodes and edges in the prediction path
  cyto.batch(() => {
    predictionPath.forEach((nodeOrEdge) => {
      nodeOrEdge.style('background-color', 'red');
    });
  });
}
