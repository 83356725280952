<template>
  <button type="button" class="col btn btn-primary btn-sm" @click="openPredictionModal">
    Predict
  </button>
  <button alt="Clear Prediction" type="button" class="col btn btn-clear" @click="clearPrediction">
    🔄
  </button>
  <div
    class="modal fade"
    id="predictionPathModal"
    ref="predictionPathModal"
    tabindex="-1"
    aria-labelledby="predictionPathModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="predictionPathModalLabel">Enter Feature Values</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div class="modal-body">
          <form @submit.prevent="generatePrediction">
            <div v-for="feature in features" :key="feature" class="mb-3">
              <p v-if="errors[feature]" class="text-danger">{{ errors[feature] }}</p>
              <label :for="'featureValueSelector' + feature" class="form-label">{{ feature }}</label>
              <!-- If our type is ordinal in the metadata, use a dropdown -->
              <select v-if="getFeatureType(feature) === 'ordinal'" :id="feature" class="form-select" @change="featureValueSelected">
                <option
                  v-for="featureValueOption in metaData.ordinal_mapping[feature]"
                  :key="feature + featureValueOption"
                  :value="featureValueOption">
                  {{ featureValueOption }}
                </option>
              </select>
              <!-- if it's categorial, allow integers -->
              <input
                v-else-if="getFeatureType(feature) === 'categorical'"
                :id="feature"
                type="number"
                class="form-control"
                placeholder="Enter number"
                @change="featureValueSelected"
              />
              <!-- otherwise it's continuous, so allow any floating point value -->
              <input
                v-else
                :id="feature"
                type="number"
                step="0.0001"
                class="form-control"
                placeholder="Enter number"
                @change="featureValueSelected"
              />
            </div>
            <button type="submit" class="btn btn-primary" :disabled="anyErrors">Predict</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap/scss/bootstrap.scss';
import { Modal } from 'bootstrap/dist/js/bootstrap';

export default {
  emits: ['clearedPrediction', 'predictionToBeGenerated'],
  props: {
    features: {
      type: Object,
      required: true,
    },
    metaData: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    features: {
      handler() {
        // when the metadata changes, we need to set the selectedFeatures to their default values
        // which for ordinal features is the first value in the list
        // for categorical features is the first value in the ordinal mapping
        // and for every else is 0
        this.selectedFeatureValues = {};
        this.features?.forEach((feature) => {
          if (this.getFeatureType(feature) === 'ordinal') {
            this.selectedFeatureValues[feature] = this.metaData?.ordinal_mapping?.[feature]?.[0];
          } else if (this.getFeatureType(feature) === 'categorical') {
            this.selectedFeatureValues[feature] = this.metaData?.ordinal_mapping?.[feature]?.[0];
          } else {
            this.selectedFeatureValues[feature] = 0;
          }
        });
      },
      deep: true,
    },
  },
  data() {
    return {
      selectedFeatureValues: {},
      errors: {},
    };
  },
  computed: {
    anyErrors() {
      return Object.values(this.errors).some((error) => error?.length);
    },
  },
  methods: {
    featureValueSelected(selection) {
      if (this.getFeatureType(selection.target.id) === 'categorical') {
        // if the feature is categorical, parse the value as an integer
        const enteredValue = parseInt(selection.target.value, 10);
        // and check if it's a valid value for the feature
        if (this.metaData.ordinal_mapping[selection.target.id].includes(enteredValue)) {
          // the mapped value is the index position in the ordinal mapping
          const foundEntry = this.metaData.ordinal_mapping[selection.target.id].indexOf(enteredValue);
          this.selectedFeatureValues[selection.target.id] = foundEntry;
          this.errors[selection.target.id] = '';
        } else {
          // if it's not, set to the closet value in the ordinal mapping
          const closestValue = this.metaData.ordinal_mapping[selection.target.id].reduce((prev, curr) => (
            Math.abs(curr - enteredValue) < Math.abs(prev - enteredValue) ? curr : prev
          ));
          this.errors[selection.target.id] = `Invalid - closest valid value: ${closestValue}`;
        }
      } else if (this.getFeatureType(selection.target.id) === 'continuous') {
        // if the number is continuous, parse it as a float
        this.selectedFeatureValues[selection.target.id] = parseFloat(selection.target.value);
      } else {
        // otherwise just take it as is
        this.selectedFeatureValues[selection.target.id] = selection.target.value;
      }
    },
    openPredictionModal() {
      this.$refs.predictionPathModal.modalInstance = new Modal(this.$refs.predictionPathModal);
      this.$refs.predictionPathModal.modalInstance.show();
    },
    clearPrediction() {
      this.$emit('clearedPrediction', this.$data);
    },
    generatePrediction() {
      this.$refs.predictionPathModal.modalInstance.hide();
      this.$emit('predictionToBeGenerated', this.selectedFeatureValues);
    },
    getFeatureType(feature) {
      return this.metaData?.type?.[feature];
    },
  },
};
</script>
